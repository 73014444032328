import {css} from '@emotion/react'

const cssContextMenuItem = css({
    display: 'grid',
    gridTemplateColumns: 'subgrid',
    alignItems: 'center',
    gap: 12,
    minHeight: 32,
    padding: '0 16px',
    backgroundColor: '#fff',

    '&:hover': {
        backgroundColor: '#fafafa',
    },
})

const cssContextMenuItemDisabled = css({
    color: '#00000040',

    '&:hover': {
        backgroundColor: '#fff',
    },
})

const cssContextMenuItemPrefix = css({
})

const cssContextMenuItemSuffix = css({
    display: 'flex',
    justifyContent: 'flex-end',
})

export default function ContextMenuItem({
    disabled,
    hasPrefix,
    hasSuffix,
    label,
    prefix,
    suffix,
    onClick,
    ...props
}) {
    const csss = [cssContextMenuItem]

    if (disabled) {
        csss.push(cssContextMenuItemDisabled)
    }

    const handleClick = (e) => {
        if (disabled) {
            return
        }

        onClick?.(e)
    }

    return (
        <div
            css={csss}
            onClick={handleClick}
            {...props}
        >
            {hasPrefix && <div css={cssContextMenuItemPrefix}>{prefix}</div>}
            {label}
            {hasSuffix && <div css={cssContextMenuItemSuffix}>{suffix}</div>}
        </div>
    )
}
