import {css} from '@emotion/react'
import {PopoverTrigger} from '@/components/Popover/index.mjs'
import ContextMenu, {ContextMenuContext, useContextMenuContext} from './ContextMenu.jsx'
import ContextMenuItem from './ContextMenuItem.jsx'

const cssTrigger = css({
    display: 'grid',
    gridTemplateColumns: 'subgrid',
})

const cssContextMenuItem = css({
    gridColumn: 'inherit',
})

export default function ContextSubMenu({
    className,
    style,
    disabled,
    items,
    ...props
}) {
    const supMenu = useContextMenuContext()

    const content = (
        <ContextMenuContext.Provider value={supMenu}>
            <ContextMenu items={items} />
        </ContextMenuContext.Provider>
    )

    return (
        <PopoverTrigger
            className={className}
            style={style}
            css={cssTrigger}
            align="top"
            content={content}
            disabled={disabled}
            position="right"
            trigger="hover"
        >
            <ContextMenuItem
                css={cssContextMenuItem}
                disabled={disabled}
                suffix="▸"
                onClick={e => e.preventDefault()}
                {...props}
            />
        </PopoverTrigger>
    )
}
