import FilterText from '../Text/FilterText.jsx'

const defaultGetText = (a) => a

const FilterDict = ({
    dict,
    getText = defaultGetText,
    ...props
}) => {
    const getFilterText = (value) => {
        const item = dict[value]
        return item ? getText(item) : ''
    }

    return (
        <FilterText
            {...props}
            getText={getFilterText}
        />
    )
}

export default FilterDict
